<template>
  <HandleDataForms
    :api-source="mongo"
    :data-source="database"
    title="Formulario Actulizacion - Antiguo"
    collection="dae_form_actualizacion"
    template-table="TableActualizacion"
    template-table-modal="TableActualizacionModal"
    :data-excel="dataExcel"
  />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const store = useStore()
    const database = computed(() => store.getters.getProduccion)
    const mongo = computed(() => store.getters.getMongoFeriasURL)
    //const mongo = 'https://api.idastage.com/api/'
    const collection = 'dae_form_actualizacion';

    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = 
    {
      fields: {
        identificador: 'Identificador',
        tipo_identificador: 'Tipo Identificador',
        email: 'Correo',
        celular: 'Celular',
        fecha: "Fecha"
      },
      query: {
        collection,
        date_field: 'fecha',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    

    return {
      mongo,
      dataExcel,
      collection,
      database
    }
  },
}
</script>
